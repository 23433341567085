<template>
  <div class="ui-content p-5" style="min-height: calc(100vh - 96px)">
    <div class="ui-content-auto">
      <div class="mb-10">
        <div class="pageContent-title mb-5">基本信息</div>
        <a-form class="flex-form a-form-mb-0 form-label-5" :form="form">
          <div class="grid grid-cols-2 gap-5">
            <a-form-item label="规则名称">
              <a-input
                placeholder="请输入规则名称"
                v-decorator="['ruleName', { rules: [{ required: true, message: '请输入规则名称' }] }]"
              ></a-input>
            </a-form-item>
            <a-form-item label="规则类型">
              <a-select
                :options="ruleTypeList"
                placeholder="请选择规则类型"
                v-decorator="['ruleType', { rules: [{ required: true, message: '请选择规则类型' }] }]"
              ></a-select>
            </a-form-item>
            <a-form-item label="规则编码">
              <a-input
                placeholder="请输入规则编码"
                v-decorator="['ruleCode', { rules: [{ required: true, message: '请输入规则编码' }] }]"
              ></a-input>
            </a-form-item>
            <a-form-item label="触发场景">
              <a-select
                :options="typesMap.scene"
                placeholder="请选择触发场景"
                v-decorator="['scene', { rules: [{ required: true, message: '请选择场景' }] }]"
              ></a-select>
            </a-form-item>
            <a-form-item label="风险等级">
              <a-select
                :options="typesMap.riskLevel"
                placeholder="请选择风险等级"
                v-decorator="['riskLevel', { rules: [{ required: true, message: '请选择风险等级' }] }]"
              ></a-select>
            </a-form-item>
            <!-- <a-form-item label="启用状态">
              <a-switch size="small" v-decorator="['status', { valuePropName: 'checked' }]" />
            </a-form-item> -->
          </div>
        </a-form>
      </div>
      <div class="mb-10">
        <div class="pageContent-title mb-5">规则条件</div>

        <div class="level-container">
          <div v-for="(group, groupIndex) in source" :key="groupIndex" class="level1">
            <div class="level1-case">
              <div v-if="groupIndex" class="case-switcher" @click="switchGroupRuleCase(groupIndex)">
                {{ getCaseName(group.ruleCase) }}
              </div>
            </div>
            <div class="level1-content">
              <div v-for="(rule, index) in group.subRuleGroups" :key="index" class="level2">
                <div class="level2-case">
                  <div v-if="index" class="case-switcher" @click="switchRuleCase(groupIndex, index)">
                    {{ getCaseName(rule.ruleCase) }}
                  </div>
                </div>
                <div class="level2-content">
                  <a-select
                    placeholder="请选择规则条件"
                    style="width: 150px"
                    v-model="rule.ruleCondition.conditionCode"
                    @change="onConditionCodeChange(rule.ruleCondition.conditionCode, groupIndex, index)"
                  >
                    <a-select-option v-for="item in rules" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                  <a-select
                    style="width: 120px"
                    placeholder="比较符"
                    v-model="rule.ruleCondition.compare"
                    :options="getCompareOptions(getValueType(rule.ruleCondition.conditionCode))"
                  ></a-select>
                  <a-input
                    style="width: 120px"
                    v-if="getValueType(rule.ruleCondition.conditionCode) === 0"
                    placeholder="请输入"
                    v-model="rule.ruleCondition.value"
                  />
                  <a-select
                    style="width: 120px"
                    v-if="getValueType(rule.ruleCondition.conditionCode) === 1"
                    placeholder="请选择"
                    :options="getOptions(rule.ruleCondition.conditionCode)"
                    v-model="rule.ruleCondition.value"
                  />
                  <a-time-picker
                    v-if="getValueType(rule.ruleCondition.conditionCode) === 2"
                    :defaultValue="getMomentValue(rule.ruleCondition.value)"
                    @change="onValueChange($event.format('HH:mm'), groupIndex, index)"
                    format="HH:mm"
                    style="width: 120px"
                  />
                </div>
                <div class="level2-ops">
                  <div class="op" v-if="index === 0">
                    <a-button type="primary" shape="circle" icon="plus" @click="addRule(groupIndex)"></a-button>
                    添加条件
                  </div>

                  <div class="op" v-if="groupIndex !== 0 && index === 0">
                    <a-button
                      type="primary"
                      shape="circle"
                      icon="minus"
                      ghost
                      @click="minusRuleGroup(groupIndex)"
                    ></a-button>
                    删除条件组
                  </div>
                  <div class="op" v-if="index !== 0">
                    <a-button
                      type="primary"
                      shape="circle"
                      icon="minus"
                      ghost
                      @click="minusRule(groupIndex, index)"
                    ></a-button>
                    删除条件
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="add-group-btn">
          <a-button type="primary" shape="circle" icon="plus" @click="addRuleGroup()"></a-button>
          添加条件组
        </div>
      </div>
      <div class="mb-10">
        <div class="pageContent-title mb-5">通知类型</div>
        <a-select
          style="width: 400px"
          placeholder="请选择通知类型"
          mode="multiple"
          :options="typesMap.actionsOptions"
          v-model="actions"
        ></a-select>
      </div>
      <div class="mb-10">
        <div class="pageContent-title mb-5">权限管理</div>
        <div class="add-group-btn">
          <a-button type="primary" shape="circle" icon="plus" @click="addRole()"></a-button>
          添加角色
        </div>
        <div class="expandedRow-table">
          <div class="expandedRow-table-head">
            <div class="expandedRow-table-row">
              <div class="expandedRow-table-col">角色名称</div>
              <div class="expandedRow-table-col">权限列表</div>
              <div class="expandedRow-table-col">操作</div>
            </div>
          </div>
          <div class="expandedRow-table-body">
            <div class="expandedRow-table-row" v-for="(item, index) in roleAuth" :key="index">
              <div class="expandedRow-table-col">
                <a-select
                  style="width: 400px"
                  placeholder="请选择角色"
                  showSearch
                  :filterOption="filterByPY"
                  :options="ruleRoleList"
                  v-model="item.role"
                ></a-select>
              </div>
              <div class="expandedRow-table-col">
                <a-select
                  style="width: 400px"
                  placeholder="请选择权限"
                  mode="multiple"
                  :options="typesMap.authOptions"
                  v-model="item.authorises"
                ></a-select>
              </div>
              <div class="expandedRow-table-col">
                <a-button type="primary" shape="circle" icon="minus" ghost @click="minusRole(index)"></a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-divider></a-divider>
    <div class="ui-content-fix flex justify-center">
      <a-button type="primary" style="width: 200px" @click="submit">提交</a-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getRuleTypeList, getRuleRoleList } from '@/service/getData/aie'
import { toolMixin } from '@/utils/mixin'

export default {
  mixins: [toolMixin],
  data() {
    return {
      id: '',
      source: [],
      // 规则下拉框
      rules: [],
      // 值下拉框,conditionCode 作为键名
      optionsMap: {},
      actions: [],
      form: this.$form.createForm(this),
      ruleTypeList: [],
      ruleRoleList: [],
      roleAuth: [],
    }
  },
  computed: {
    typesMap() {
      return {
        riskLevel: [
          {
            label: '高风险',
            value: '1',
          },
          {
            label: '中风险',
            value: '2',
          },
          {
            label: '低风险',
            value: '3',
          },
        ],
        scene: [
          {
            label: '车辆进',
            value: '0',
          },
          {
            label: '车辆出',
            value: '1',
          },
          {
            label: '20分钟未过磅',
            value: '2',
          },
          {
            label: '凌晨统计',
            value: '3',
          },
          {
            label: '设备离线',
            value: '4',
          },
        ],
        actionsOptions: [
          {
            label: '短信通知',
            value: 'smsActionExecutor',
          },
          {
            label: '企业微信通知',
            value: 'wechatActionExecutor',
          },
        ],
        authOptions: [
          {
            label: '处置',
            value: 'disposeExecutor',
          },
          {
            label: '复核',
            value: 'reviewExecutor',
          },
        ],
      }
    },
  },
  async mounted() {
    this.loadRuleTypeList()
    this.loadRuleRoleList()
    await this.loadRules()
    const id = this.$route.query.id
    if (id) {
      this.id = id
      await this.loadById(id)
    }
    if (!this.source.length) {
      this.addRuleGroup()
    }
  },
  methods: {
    async loadRuleTypeList() {
      this.ruleTypeList = await getRuleTypeList()
    },
    async loadRuleRoleList() {
      this.ruleRoleList = await getRuleRoleList()
    },
    async loadById(id) {
      const res = await this.$axios.post('/aie/web/rule/select', {
        id,
      })
      const {
        source,
        action,
        riskLevel,
        ruleType,
        ruleCode,
        ruleName,
        scene,
        authorises,
        // status
      } = res.data
      this.form.setFieldsValue({
        ruleType,
        riskLevel,
        ruleCode,
        ruleName,
        scene,
        // status: Boolean(status),
      })

      this.source = JSON.parse(source)

      this.actions = JSON.parse(action).map((item) => {
        return item.code
      })
      for (let i = 0; i < this.source.length; i++) {
        for (let j = 0; j < this.source[i].subRuleGroups.length; j++) {
          const condition = this.source[i].subRuleGroups[j].ruleCondition
          const valueType = this.getValueType(condition.conditionCode)
          console.log(valueType, condition.conditionCode)
          if (valueType === 1) {
            await this.loadRuleOptions(condition.conditionCode)
          }
        }
      }
      this.roleAuth = authorises
    },
    async loadRules() {
      const res = await this.$axios.post('/aie/web/rule/condition/list')
      this.rules = res.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
          origin: item,
        }
      })
    },
    async loadRuleOptions(conditionCode) {
      if (this.optionsMap[conditionCode]) {
        return
      }
      const res = await this.$axios.post('/aie/web/rule/condition/select/list', {
        conditionCode,
      })
      const options = res.data.map((item) => {
        return {
          label: item.name,
          value: item.code,
          origin: item,
        }
      })
      this.$set(this.optionsMap, conditionCode, options)
    },
    getOptions(conditionCode) {
      return this.optionsMap[conditionCode] || []
    },
    getValueType(conditionCode) {
      const matched = this.rules.find((item) => item.value === conditionCode)?.origin
      return matched ? matched.valueType : 0
    },
    getCompareOptions(valueType) {
      if (valueType === 1) {
        return [
          {
            label: '等于',
            value: 'eq',
          },
        ]
      }
      if (valueType === 0 || valueType === 2) {
        return [
          {
            label: '大于',
            value: 'gt',
          },
          {
            label: '大于等于',
            value: 'ge',
          },
          {
            label: '等于',
            value: 'eq',
          },
          {
            label: '小于等于',
            value: 'le',
          },
          {
            label: '小于',
            value: 'lt',
          },
        ]
      }
      return []
    },
    getMomentValue(value) {
      const timeString = `${moment().format('YYYY-MM-DD')} ${value}`
      return value ? moment(timeString) : undefined
    },
    getCaseName(ruleCase) {
      if (ruleCase === 'AND') {
        return '且'
      }
      if (ruleCase === 'OR') {
        return '或'
      }
    },
    toNegtiveRuleCase(ruleCase) {
      if (ruleCase === 'AND') {
        return 'OR'
      }
      if (ruleCase === 'OR') {
        return 'AND'
      }
    },
    switchRuleCase(groupIndex, index) {
      this.source[groupIndex].subRuleGroups[index].ruleCase = this.toNegtiveRuleCase(
        this.source[groupIndex].subRuleGroups[index].ruleCase
      )
    },
    switchGroupRuleCase(groupIndex) {
      this.source[groupIndex].ruleCase = this.toNegtiveRuleCase(this.source[groupIndex].ruleCase)
    },

    ruleGroupEntity(ruleCase = 'OR') {
      return {
        ruleCase,
        subRuleGroups: [this.ruleEntity()],
      }
    },
    ruleEntity() {
      return {
        ruleCase: 'AND',
        ruleCondition: {
          conditionCode: undefined,
          compare: undefined,
          value: undefined,
        },
      }
    },
    addRuleGroup() {
      const ruleCase = this.source.length ? 'OR' : 'AND'
      this.source.push(this.ruleGroupEntity(ruleCase))
    },
    minusRuleGroup(groupIndex) {
      this.source.splice(groupIndex, 1)
    },
    addRule(groupIndex) {
      this.source[groupIndex].subRuleGroups.push(this.ruleEntity())
    },
    minusRule(groupIndex, index) {
      this.source[groupIndex].subRuleGroups.splice(index, 1)
    },

    onValueChange(value, groupIndex, index) {
      this.$set(this.source[groupIndex].subRuleGroups[index].ruleCondition, 'value', value)
    },
    async onConditionCodeChange(conditionCode, groupIndex, index) {
      const matched = this.rules.find((item) => item.value === conditionCode)?.origin
      const valueType = matched.valueType
      const compare = valueType === 1 ? 'eq' : undefined
      this.$set(this.source[groupIndex].subRuleGroups[index].ruleCondition, 'conditionCode', conditionCode)
      this.$set(this.source[groupIndex].subRuleGroups[index].ruleCondition, 'compare', compare)
      this.$set(this.source[groupIndex].subRuleGroups[index].ruleCondition, 'value', undefined)
      this.$set(this.source[groupIndex].subRuleGroups[index].ruleCondition, 'valueType', valueType)
      this.loadRuleOptions(conditionCode)
    },
    submit() {
      console.log(JSON.parse(JSON.stringify(this.source)))
      this.form.validateFields(async (errors, values) => {
        if (!errors) {
          for (let i = 0; i < this.source.length; i++) {
            for (let j = 0; j < this.source[i].subRuleGroups.length; j++) {
              const condition = this.source[i].subRuleGroups[j].ruleCondition
              if (!condition.conditionCode || !condition.compare || !condition.value) {
                return this.$message.error('请完整填写规则')
              }
            }
          }
          if (!this.actions.length) {
            return this.$message.error('请选择执行动作')
          }
          if (!this.roleAuth.length) {
            return this.$message.error('请至少添加一个角色')
          }
          for (let i = 0; i < this.roleAuth.length; i++) {
            if (!this.roleAuth[i].role) {
              return this.$message.error('请选择角色')
            }
            if (!this.roleAuth[i].authorises.length) {
              return this.$message.error(`权限管理明细${i + 1}请选择角色权限`)
            }
          }
          const params = {
            ...values,
            conditions: this.source,
            actions: this.actions.map((action) => {
              return {
                code: action,
              }
            }),
            authorises: this.roleAuth,
          }
          if (!this.id) {
            await this.$axios.post('/aie/web/rule/add', params)
          } else {
            await this.$axios.post('/aie/web/rule/update', {
              id: this.id,
              ...params,
              // status:Number(params.status)
            })
          }
          this.$message.success('操作成功')
        }
      })
    },
    addRole() {
      this.roleAuth.push({
        authorises: [],
        role: undefined,
      })
    },
    minusRole(index) {
      this.roleAuth.splice(index, 1)
    },
  },
}
</script>

<style lang="less" scoped>
.level-container {
  .level1 {
    display: flex;
    padding-bottom: 20px;
    position: relative;
    // .level1-content {
    //   // flex: 1;
    // }
    &:first-child {
      .level1-case {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 14px;
          left: 15px;
          width: 120px;
          height: 0px;
          border-bottom: 1px dashed #cccc;
        }
      }
    }
    &:last-child {
      .level1-case {
        &:before {
          display: none;
        }
      }
    }
    .level1-case {
      width: 60px;
      height: 100%;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 14px;
        left: 15px;

        width: 120px;
        height: 100%;
        border-bottom: 1px dashed #cccc;
        border-left: 1px dashed #ccc;
      }
    }
    .level2 {
      display: flex;
      align-items: center;
      height: 35px;
      &:first-child {
        .level2-case {
          &:before {
            display: none;
          }
        }
      }
      .level2-case {
        width: 60px;
        position: relative;
        height: 100%;

        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 50%;
          right: 0;

          width: 46px;
          height: 40px;
          border-bottom: 1px dashed #cccc;
          border-left: 1px dashed #ccc;
        }
      }
      .level2-content {
        // flex: 1;
        display: flex;
        padding-right: 10px;
        gap: 10px;
      }
      .level2-ops {
        display: flex;
        .op {
          margin-right: 10px;
        }
      }
    }
  }
}
.case-switcher {
  border: 1px solid #ccc;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  position: relative;
  z-index: 1;
  background: #fff;
}
.expandedRow-table {
  .expandedRow-table-row {
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }
  .expandedRow-table-head {
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    font-weight: bold;
  }
  .expandedRow-table-body .expandedRow-table-row:last-child {
    .expandedRow-table-col {
      border-bottom: 0;
    }
  }
  .expandedRow-table-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 5px;
    border-bottom: 1px solid #dfdfdf;
  }
  .expandedRow-table-body {
    .expandedRow-table-col {
      padding: 12px 8px;
    }
  }
  .expandedRow-table-head {
    font-weight: bold;
  }
}
</style>
